<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
export default {
  name: 'scenicAreasTicket',
  data() {
    return api.command.getState()
  },
  mounted() {
    const { id } = this.$route.query
    api.command.getList.call(this, {
      url: '/goods/farmTicket/page',
      paramsValue: {
        id: id,
      },
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '门票类型',
          key: 'productName',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
        // {
        //   name: '店铺名称',
        //   type: 'input',
        //   key: 'mainBodyName'
        // }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'productName',
          title: '门票类型',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        // {
        //   dataIndex: 'linkShopName',
        //   title: '店铺名称',
        //   align: 'left',
        //   sorter: (a, b) => a.linkShopName.length - b.linkShopName.length
        // },
        {
          dataIndex: 'readCount',
          title: '访问量',
          align: 'left',
          sorter: (a, b) => a.readCount - b.readCount,
        },
        {
          dataIndex: 'saleCount',
          title: '销量',
          align: 'left',
          sorter: (a, b) => a.saleCount - b.saleCount,
        },
        {
          dataIndex: 'hxCount',
          title: '核销量',
          align: 'left',
          sorter: (a, b) => a.hxCount - b.hxCount,
        },
        {
          dataIndex: 'allStock',
          title: '库存',
          align: 'left',
          sorter: (a, b) => a.allStock - b.allStock,
        },
        {
          dataIndex: 'unUsedStock',
          title: '剩余库存',
          align: 'left',
          sorter: (a, b) => a.unUsedStock - b.unUsedStock,
        },
        {
          dataIndex: 'useStartTime',
          title: '有效期',
          align: 'left',
          isId: true,
          sorter: (a, b) => moment(a.hxStartTime).unix() - moment(b.hxStartTime).unix(),
          customRender: function (text, records) {
            return records.hxExpireType == '1' ? (
              `必须在购买后${records.hxAfterDay}天内使用`
            ) : records.hxStartTime == null ? (
              ''
            ) : (
              <div>
                {moment(records.hxStartTime).format('YYYY/MM/DD') +
                  '~' +
                  moment(records.hxEndTime).format('YYYY/MM/DD')}
              </div>
            )
          },
          onExport: (text, records) => {
            return records.hxExpireType == '1' ? (
              `必须在购买后${records.hxAfterDay}天内使用`
            ) : records.hxStartTime == null ? (
              ''
            ) : (
              <div>
                {moment(records.hxStartTime).format('YYYY/MM/DD') +
                  '~' +
                  moment(records.hxEndTime).format('YYYY/MM/DD')}
              </div>
            )
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/goods/farmTicket/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '日历价格',
                onClick: () =>
                  this.$router.push(
                    `/commodityManagement/calendar?productId=${records.id}&productType=1&thirdTicket=${records.thirdTicket}`
                  ),
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/scenicAreasTicketDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmTicket?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      const { id, shopId } = this.$route.query
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () =>
            this.$router.push(`/commodityManagement/scenicAreasTicketDetail?scenicId=${id}&shopId=${shopId}`),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/goods/farmTicket/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmTicket/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmTicket/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
        // {
        //   name: '页面设置',
        //   type: 'primary',
        //   ghost: true,
        //   onClick: () => this.$router.push('/commodityManagement/scenicAreasPageSetDetail')
        // }
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
